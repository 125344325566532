<script setup lang="ts">
import { useNotification } from '../composables/useNotification';

const { queue } = useNotification();
</script>

<template>
    <Teleport to="#teleports">
        <div
            class="fixed bottom-14 left-[50%] z-50 ml-[-250px] h-fit w-[500px]"
        >
            <div class="flex flex-col items-center justify-center gap-4">
                <JamBaseNotification
                    v-for="(item, index) in queue"
                    :key="`${item.id}-${index}`"
                    :close-button="item.closeButton"
                    :variant="item.variant"
                    :link="item.link"
                    :on-close="item.onClose"
                    :duration="item.duration"
                    :icon="item.icon"
                >
                    {{ item.message }}
                </JamBaseNotification>
            </div>
        </div>
    </Teleport>
</template>
